import contactServices from '../../../services/contact'

export default {
    data() {
        return {
            item: {
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                send_to: null,
                message: null
            },
            listEmail: [],
            formKey: 0
        }
    },
    mounted() {
        this.fetchEmailList()
    },
    methods: {
        async submit() {
            var valid = await this.$refs.observer.validate()
            if (valid) {
                contactServices.submitContact(this.item).then(resp => {
                    if (resp.data.ok) {
                        this.item = {
                            first_name: null,
                            last_name: null,
                            email: null,
                            phone: null,
                            send_to: null,
                            message: null
                        }
                        this.formKey += 1
                        this._showToast('Sent successfully!')
                    } else {
                        this._showToast('Could not send contact email', {variant: 'danger'})
                    }
                })
            }
        },
        fetchEmailList() {
            contactServices.getEmails().then(resp => {
                this.listEmail = resp.data.d
            })
        }
    }
}