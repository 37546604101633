import http from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const contactServices = {
    getEmails() {
        return http.get(`${API_ROOT}/contact/emails`).then(resp => {
            return resp
        })
    },
    submitContact(payload) {
        return http.post(`${API_ROOT}/contact`, payload).then(resp => {
            return resp
        })
    }
}

export default contactServices
